html,
body,
#root {
  height: 100%;
  background-color: #f3f5f7;
}
body {
  margin: 0px;
  font-size: 0.875rem !important;
  padding: 0px !important;
  color: #242c34;
  font-family: "Inter", sans-serif;
}

@keyframes shine-lines {
  0% {
    background-position: -100px;
  }

  40%,
  100% {
    background-position: 140px;
  }
}

@keyframes toOpen {
  from {
    opacity: 0.5;
  }
  to {
    opacity: 1;
  }
}

@keyframes toClose {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0.5;
  }
}

/* Override Material UI CSS */
.MuiInput-underline:before,
.MuiInput-underline:after {
  border-bottom: 0 !important;
}

.MuiCheckbox-colorSecondary.Mui-checked {
  color: #1576d6 !important;
}

.react-datepicker__day--selected {
  background-color: #1576d6 !important;
}

.react-datepicker__header--time {
  height: 62px;
}

.react-datepicker__time-list-item {
  height: initial !important;
  padding: 5px !important;
}

/* DataGrid CSS */
.ag-center-cols-container {
  width: 100% !important;
}

.ck-editor__editable {
  height: 200px !important;
}
.react-datepicker__triangle{
	left: -7px !important;
}

.overflow-container{
	overflow: hidden;
	position: relative;
	height: 68px;
}

.overflow-container:before {
	content:"";
	float: left;
	width: 5px; height: 200px; }

.overflow-container > *:first-child {
	float: right;
	width: 100%;
	margin-left: -5px; }		

.overflow-container:after {
	content: "\02026 Read More"; 

	box-sizing: content-box;
	-webkit-box-sizing: content-box;
	-moz-box-sizing: content-box;

	float: right; position: relative;
	top: -25px; left: 100%; 
	width: 7em; margin-left: -7em;
	padding-right: 5px;
	
	text-align: right;
  
  background-size: 100% 100%;
  /* 1706x1 image, gradient for IE9. Transparent at 0% -> white at 15% -> white at 100%.*/  
  background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAABqoAAAABCAMAAACRxg/eAAAABGdBTUEAALGPC/xhBQAAAwBQTFRF////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////AAAA////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////wDWRdwAAAP90Uk5Tfet1sDrNWZIeSRDcocCFLWj1CbhSfuMl1EGoYJkYM8Zui/rwBk15FefYKrxGtKydZQzfIdDJNj1VpFyVcY6BBBwxw2yI/Pfy7RInQ09ie5B0eIMDD+kb5eEk2tbSMMs5QL66tkyyrqpYpl+fm5drAf0H+AoN8+4TFhkf3SIoKwDONDfHO8Q+wURHSlBTVlqiXWNmaZNvcox2iYZ/fAL+9vsFCPkL9A4R8e/sFOjqFxrm5B3i4CDeIybb2SnX1SzT0S/PMsw1yjjIxTzCP79CvbtFuUi3tUuzTrGvUa1Uq6lXp6Vbo16gYZ6cZJpnmJZqlG2RcI9zjYp6d4eChIAu7+D8pQAAASFJREFUOMtjqK+fG2y/UKVwkVDxEsOSZSFcyz3LV6tXrJOtXB/msImjaqtxzfZI8R1etbu16vbx/zsY63hYrvGoWdPxeJaWU+ZtZxIkz3l3XNTpvMzZdS1F9YZP922RnrsWvQ8y2PoeW014miX93HfiK4NJbwQmv89z/qgw9bPNtK8BTDN+Bqr98JvDIDr7r93voD98s365zPyuOP2b7Rf/T+xTPli/y30r8zrnpf6L7Gc8/U8sH2U+lLqffk/vTtot5pup13WvJl8RvpR0Qft84lne9tNOrSflm0+YHos7wtpwyORAzH6JvdF7NHdF7eSu3ma0JWKz2MbwDRprQtcyrvIoW6m8wr10qeB8t6LFSgtcC+b9HwWjYBSMglEw2AEA3ckkQdBniOMAAAAASUVORK5CYII=);


	background: -webkit-gradient(linear, left top, right top,
		from(rgba(255, 255, 255, 0)), to(white), color-stop(15%, white));
	background: -moz-linear-gradient(to right, rgba(255, 255, 255, 0), white 15%, white);			
	background: -o-linear-gradient(to right, rgba(255, 255, 255, 0), white 15%, white);
	background: -ms-linear-gradient(to right, rgba(255, 255, 255, 0), white 15%, white);
	background: linear-gradient(to right, rgba(255, 255, 255, 0), white 15%, white);  }

.overflow-container a { position: absolute;
	left: 0; top: 0;
	width: 100%; height: 100%;
	overflow: hidden; text-indent: -9999px; }
.overflow-container i, .overflow-container:after {
	font-style: normal; color: blue;}

.overflow-container i:before { content: "Read More";}

.react-datepicker{
  display: flex !important;
}