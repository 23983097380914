.auth-container {
  font-size: 0.875rem;
}

.auth-container>ol,
.auth-container>ul {
  margin: 0;
  margin-left: 2rem;
  padding: 0;
}

.heading-container>.heading {
  font-size: 1rem;
  font-weight: bold;
}

.heading-container {
  display: flex;
  align-items: center;
  margin-bottom: 0.5rem;
}

.auth-container>ol>li,
.auth-container>ul>li {
  text-align: left;
  margin-bottom: 0.5rem;
  line-height: 21px;
}