html,
body,
#root {
  height: 100%;
}
body {
  font-size: 0.875rem !important;
  padding: 0px !important;
}

.site_down_page {
  position: relative;
  height: 100%;
}

.site_down_page_container {
  position: absolute;
  margin: 0;
  top: 40%;
  text-align: center;
  width: 100%;
  padding: 1rem;
  box-sizing: border-box;
  transform: translateY(-60%);
}

.site_down_page_heading {
  font-size: 3rem;
  font-weight: 500;
  margin-top: 2.5rem;
}

.site_down_page_content {
  font-size: 1.25rem;
  margin-top: 1.25rem;
  color: rgb(36, 44, 52);
}
