/* Okta Login Container */
#okta-sign-in.auth-container.main-container{
	box-shadow: 0px 1px 3px rgb(0 0 0 / 7%);
	background-color: #ffffff;
	border: none;
}

/* Header title */
#okta-sign-in.auth-container .o-form-head {
	font-size: 16px;
	font-weight: 600;
	font-stretch: normal;
	font-style: normal;
	line-height: 1.5;
	letter-spacing: normal;
	color: #242c34;
}
/* Primary Button */
#okta-sign-in.auth-container .button-primary{
	background: #1576d6 !important;
}
/* Site Logo */
#okta-sign-in .auth-org-logo{
	width: 211.6px;
    height: 32px;
}
.btn-disabled{
	opacity: 0.5;
}